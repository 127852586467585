import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Image from "components/image";
import Socials from "components/socials";
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import SmallBox from "components/smallBox";
import TableDescription from 'components/cpu/table_description';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/extreme"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/cpuextreme.png"}) { ...eyecatchImg },
    rendering: file(relativePath: { eq: "cpu/rendering.png"}) { ...normalImg },
    allExtremeJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
        rank
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【ThreadRipper/Core iX】最強のCPU50選`}</h1>
    <time itemProp="datePublished" dateTime="Sat Aug 24 2024 01:01:07 GMT+0900 (日本標準時)">更新日:2024年8月24日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="最強のCPU50選" mdxType="Image" />
    <p>{`4K動画のエンコードや3DCG制作におけるレンダリングなどクリエイティブな作業において、CPUは速いほど時間が短縮され、作業が快適となる。あるいはゲームにおいてより高いFPSを目指す時にはGPUだけではなく、CPUの処理性能が重要となってくる。`}</p>
    <p>{`そこで本サイトではベンチマークで圧倒的な値を叩きだす個人向け最強のCPUを50選してランキングで提示する。`}</p>
    <p>
      <Link to="/cpu/" mdxType="Link">CPU性能比較表</Link>もあるので一般的なCPUを手に入れたい人はそちらを参考にして欲しい。紹介する順位表の見方等は下記を参考。
    </p>
    <BenchMark mdxType="BenchMark" />
    <CpuMatsubi mdxType="CpuMatsubi" />
    <TableDescription mdxType="TableDescription" />
    <HowtoFilter mdxType="HowtoFilter" />
    <h2 {...{
      "id": "最強CPUの種類",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%9C%80%E5%BC%B7CPU%E3%81%AE%E7%A8%AE%E9%A1%9E",
        "aria-label": "最強CPUの種類 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`最強CPUの種類`}</h2>
    <p>{`Windowsパソコン用のCPUとしてはAMDとIntelの２社が独占状態であるが、それぞれが目的に応じた最強のCPUをリリースしている。`}</p>
    <AccentBox title="AMD/Intelの最強CPUシリーズ" mdxType="AccentBox">
  <li><span className="bold">ThreadRipper</span>・・・プロの動画制作現場で使われることを想定してマルチコア性能に比重をおいたAMDの大型CPU</li>
  <li><span className="bold">Ryzen X</span>・・・ゲーミングや動画制作など一般用途でハイパフォーマンスを追求したRyzenのシリーズ。XはExtremeを意味する</li>
  <li><span className="bold">Core i9 Xシリーズ</span>・・・プロの動画制作現場で使われることを想定したIntelのマルチコアプロセッサ</li>
  <li><span className="bold">Core i9 Kシリーズ</span>・・・シングルスレッド性能が高く、最強のゲーミングパフォーマンスを追求したIntelのプロセッサ。マルチコア化も進みクリエイティブな作業も快適</li>
    </AccentBox>
    <p>{`これらのCPUは一般的なWebブラウジングやオフィス用途は快適なことはもちろん、`}<strong parentName="p">{`クリエイティブな領域とゲーミングの領域にそれぞれ特化した性能`}</strong>{`を持つ。`}</p>
    <p>{`特にクリエイティブな領域における映像編集のレンダリング作業は膨大な計算量が要求され、非常に時間がかかるため、マルチコア性能を可能な限り引き上げて時間を短縮しようと各社奮闘している。`}</p>
    <p>{`3Dモデリングソフト(Blender)から光の物理演算をして下記の画像を一枚作るだけでもデスクトップ用Core i5CPUで8分かかるので、時間短縮によるメリットはかなり大きい。`}</p>
    <Image {...props} name="rendering" alt="CPUレンダリングの過程" mdxType="Image" />
    <p>{`映画の制作現場では１フレーム生成に最長６時間かかったなど苦労話には事欠かない。ともあれ長くなるので動画編集の話は下記リンクに任せるとする。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
      <Link to="/editvideo/" mdxType="Link">本格的な動画編集を行いたい人へ向けたパソコンの選び方</Link>
    </SmallBox>
    <p>{`ゲーミングの領域に関しては、マルチスレッドが同様に性能に影響を与えるものの、一定数以上のコア数を有効活用できないなどゲームライブラリ側の制約もあるため基本的にはシングルコアの性能が高いものがパフォーマンスを得やすくなっている。PASSMARKのシングルスコア`}<em parentName="p">{`PASSMARK(S)`}</em>{`を特に参考にするとよい。`}</p>
    <p>{`FullHD60FPSという通常の環境でプレイするには十分すぎるので、144FPSや4Kでより滑らかな映像を楽しみたい時にCPU性能が必要となることは理解しておこう。ハイエンドGPUと組み合わせて使う時以外には恩恵は受けられないであろう。`}</p>
    <h2 {...{
      "id": "最強CPU50選",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%9C%80%E5%BC%B7CPU50%E9%81%B8",
        "aria-label": "最強CPU50選 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`最強CPU50選`}</h2>
    <p>{`それでは最強のCPUをPASSMARKの高い順に並べて紹介していく。用途によって最適なCPUは異なるものの一般的なノートCPUがせいぜいPASSMARK10000点とするとその何倍も高性能なパソコンがずらりと並ぶことが分かる。是非あなたのロマンを実現するための参考にして欲しい。`}</p>
    <HikakuTable {...props} checkboxes={['corei', 'ryzen', 'apple', 'notedesk']} cpuData={props.data.allExtremeJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      